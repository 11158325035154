import { VoteOption } from './constant'
import BN from 'big.js'
import numeral from 'numeral'
import cogoToast from 'cogo-toast'
import { getFeeParamsByMsgs, MsgType } from '../config/fee'
import getNetworkConfig from '../config/network'
import msgTypes from './msgTypes'
import enTrans from '../locale/en_US'
import zhTrans from '../locale/zh_CN'

export const Toast = cogoToast

BN.DP = 40
BN.RM = 0

export interface AccountInfo {
  address: string
  account_number: string
  pub_key?: {
    '@type': string
    key: string
  }
  balance: string
  sequence?: string
}

export async function createTxPayload(
  from: string,
  msgs: MsgType[],
  memo: string,
  account?: AccountInfo,
) {
  const { feeAmount, gasLimit } = await getFeeParamsByMsgs(msgs, account)

  const payload = {
    from: from,
    chainType: 'COSMOS',
    fee: {
      amount: [{ amount: feeAmount, denom: getNetworkConfig().denom }],
      gas: gasLimit,
    },
    msgs,
    memo,
  }
  return payload
}

export function createTransferMsg(fromAddr, toAddr, amount, denom) {
  return {
    typeUrl: msgTypes.send,
    value: {
      amount: [{ amount: amount, denom: denom }],
      fromAddress: fromAddr,
      toAddress: toAddr,
    },
  }
}

export function createDelegateMsg(delegatorAddress, validatorAddress, amount, denom) {
  return {
    typeUrl: msgTypes.delegate,
    value: {
      delegatorAddress: delegatorAddress,
      validatorAddress: validatorAddress,
      amount: { amount: amount, denom: denom },
    },
  }
}

export function createUnDelegateMsg(delegatorAddress, validatorAddress, amount, denom) {
  return {
    typeUrl: msgTypes.undelegate,
    value: {
      delegatorAddress: delegatorAddress,
      validatorAddress: validatorAddress,
      amount: { amount: amount, denom: denom },
    },
  }
}

export function createWithdrawMsg(delegatorAddress, validatorAddress) {
  return {
    typeUrl: msgTypes.withdraw,
    value: {
      delegatorAddress: delegatorAddress,
      validatorAddress: validatorAddress,
    },
  }
}

export function createRedelegateMsg(
  delegatorAddress,
  validatorSrcAddress,
  validatorDstAddress,
  amount,
  denom,
) {
  return {
    typeUrl: msgTypes.redelegate,
    value: {
      delegatorAddress: delegatorAddress,
      validatorSrcAddress: validatorSrcAddress,
      validatorDstAddress: validatorDstAddress,
      amount: { amount: amount, denom: denom },
    },
  }
}

export function createVoteMsg(from: string, proposalId: string, option: VoteOption) {
  return {
    typeUrl: msgTypes.vote,
    value: {
      voter: from,
      proposalId: proposalId,
      option,
    },
  }
}

export function createProposalMsg(from, title, description, amount, denom) {
  return {
    typeUrl: msgTypes.proposal,
    value: {
      content: {
        typeUrl: msgTypes.textProposal,
        value: {
          title,
          description,
        },
      },
      initialDeposit: [
        {
          denom: denom,
          amount,
        },
      ],
      proposer: from,
    },
  }
}

export function createDepositMsg(from, proposalId, amount, denom) {
  return {
    typeUrl: msgTypes.deposit,
    value: {
      proposalId: proposalId,
      depositor: from,
      amount: [
        {
          denom,
          amount,
        },
      ],
    },
  }
}

export const ellipsis = (str: string, lead: number = 12, tail: number = 6): string => {
  if (str && str.length > lead + tail + 8) {
    return `${str.substring(0, lead)}...${str.substring(str.length - tail, str.length)}`
  }
  return str
}

export const toBN = x => {
  if (isNaN(Number(x))) return new BN(0)
  if (x instanceof BN) return x
  return new BN(x)
}

export const uatom = (atom: string | number) => {
  return toBN(atom).times(1e6).toFixed()
}

export const atom = (uatom: string | number) => {
  return toBN(uatom).div(1e6).toFixed()
}

/**
 * used for render balance in jsx
 * the decimals length depend on the value
 * if value < 1, at least keep the non-zero and following four places
 * if integer, keep interger
 * if otherwise, keep ${decimalLength} places decimals
 */
export const formatSmartBalance = (
  num: number | string,
  defaultDecimalLength: number = 4,
  maxDecimalLength: number = 6,
) => {
  const valueBN = toBN(num)
  const valueString = valueBN.toFixed()

  if (valueBN.eq(valueBN.toFixed(0, 1))) {
    return thousandCommas(valueString, 0)
  }

  if (valueBN.lt(1)) {
    for (let i = 2; i < valueString.length; i++) {
      if (i >= 2 + maxDecimalLength) return '0' // if 0.000000* return 0
      if (valueString[i] !== '0') {
        let max = Math.min(maxDecimalLength, i + defaultDecimalLength) // display 0. + maxDecimalLength zero at most.
        return valueBN.toFixed(Math.max(max, defaultDecimalLength)).replace(/[0]+$/, '')
      }
    }
  }

  return thousandCommas(valueBN.toFixed(defaultDecimalLength), defaultDecimalLength)
}

/**
 * format uatom balance in atom
 * used for render balance in jsx
 * don't used for calc
 */
export const fAtom = (uatom: string | number, decimalLength = 4, placeholader = '~') => {
  if (isNaN(Number(uatom))) return placeholader
  return formatSmartBalance(atom(uatom), decimalLength)
}

export const thousandCommas = (num: string | number, place: number = 4) => {
  const decimals = '0'.repeat(place)
  return numeral(num).format(`0,0.[${decimals}]`)
}

export const fPercent = (p: number, fixed = 3) => {
  return !isNaN(Number(p)) ? `${(p * 100).toFixed(fixed)}%` : '~'
}

export const isExist = (o: any) => {
  return typeof o !== 'undefined'
}

export const getDailyReward = (delegateShares, annualizedTeturns) => {
  const dailyReward = toBN(delegateShares).times(annualizedTeturns).div(365).toFixed()
  return fAtom(dailyReward, 3)
}

export const getBalanceFromAccount = coins => {
  if (!coins || !Array.isArray(coins)) return 0
  const atom = coins.find(c => c.denom === 'uatom' || c.denom === 'umuon')
  return atom ? atom.amount || 0 : 0
}

export const getDeletationBalance = delegations => {
  let balance = 0
  if (Array.isArray(delegations)) {
    delegations.forEach(d => {
      balance += d.balance.amount * 1
    })
  }
  return balance.toFixed(0)
}

export const getRewardBalance = rewards => {
  let balance = 0
  if (Array.isArray(rewards)) {
    // only atom tokens are counted
    const atomRewards = rewards.filter(reward => reward.denom === 'uatom')
    atomRewards.forEach(d => {
      balance += d.amount * 1
    })
  }

  // Repair execution of "reinvestment income" failed tx
  // https://imtoken.atlassian.net/browse/OPS-1582
  return `${Math.floor(balance)}`
}

export const getUnbondingBalance = unbondingDelegations => {
  let balance = 0
  if (Array.isArray(unbondingDelegations)) {
    unbondingDelegations.forEach(d => {
      if (Array.isArray(d.entries)) {
        d.entries.forEach(o => {
          balance += o.balance * 1
        })
      }
    })
  }
  return balance.toFixed(0)
}

export const isiPhoneX = () => {
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812
  }
  return false
}

export const getLocale = () => {
  let val = navigator.language || ''
  const locale = val.toLowerCase().split(/[^\w+]/gi)[0] || 'en'
  return locale === 'zh' ? 'zh' : 'en'
}

export const getAmountFromMsg = msg => {
  if (!msg || !msg.value || !msg.value.amount) return '0'
  const amountObj = msg.value.amount
  return amountObj && amountObj.amount
}

/**
 * zh: {
 *   delegate: '委托',
 *   delegate_token_get_rewards: '委托 $s 获取收益 $s 每天',
 * }
 *
 * t('delegate') ---> '委托'
 * t('delegate_token_get_rewards', 'Atom', 'Btom') --->  '委托 Atom 获取收益 Btom 每天'
 *
 */
let trans: any = null
export const t = (key, ...args) => {
  if (!trans) {
    const locale = getLocale()
    trans = locale === 'zh' ? zhTrans : enTrans
  }

  let value = trans[key] || key
  if (args.length) {
    let i = 0
    value = value.replace(/\$s/gi, () => {
      const o = args[i] || ''
      i++
      return o
    })
  }

  return value
}

/**
 * check current page is load by reload
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
 */
export const isReload = () => {
  let isReload = false

  if (window.performance && performance.getEntriesByType) {
    const perfEntries = performance.getEntriesByType('navigation')

    for (var i = 0; i < perfEntries.length; i++) {
      var p: any = perfEntries[i]
      if (p.type === 'reload') {
        isReload = true
      }
    }
  }
  return isReload
}

export function compareSemver(a, b) {
  const pa = a.split('.')
  const pb = b.split('.')
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i])
    const nb = Number(pb[i])
    if (na > nb) return 1
    if (nb > na) return -1
    if (!isNaN(na) && isNaN(nb)) return 1
    if (isNaN(na) && !isNaN(nb)) return -1
  }
  return 0
}

export const isBrowser = (): boolean => {
  return Boolean(typeof window !== 'undefined' && window.document && window.document.createElement)
}

export const isiOS = () => {
  if (typeof window === 'undefined' || !window.navigator) return false
  return /iP(ad|hone|od)/.test(window.navigator.platform)
}

export function debounce<Func extends (...args: any[]) => any>(fn: Func, ms = 300): Func {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  } as unknown as Func
}
