import React, { useMemo } from 'react'
import styles from './index.module.scss'

export interface GlassLayerProps {
  zIndex?: number
  className?: string
}

export type GlassLayerWithHTML = GlassLayerProps &
  Omit<React.HTMLAttributes<unknown>, keyof GlassLayerProps>

const GlassLayer: React.FC<GlassLayerWithHTML> = ({ zIndex = 100, className, ...props }) => {
  const customStyles = useMemo(() => {
    return {
      zIndex,
    }
  }, [zIndex])
  return (
    <div className={`${styles.container} ${className || ''}`} style={customStyles} {...props} />
  )
}

export default GlassLayer
