import React, { useEffect, useState } from "react";
import Modal from "./GeoModal";
import { t } from "lib/utils";

export const GEO_STORAGE_KEY = "GEO_STORAGE_KEY";
export const unauthorizedGeoCode = ["cn"];
const getGeoCodeFromHttpHeaders = (): Promise<string> =>
  new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        const countryCode = `${req.getResponseHeader(
          "X-Geoip-City-Country-Code"
        )}`.toLowerCase();
        return resolve(countryCode);
      }
    };
    req.onerror = () => reject("unknown");
    req.onabort = () => reject("unknown");
    req.ontimeout = () => reject("unknown");
    req.open("GET", window.location.href, false);
    req.setRequestHeader("cache-control", "public, max-age=60");
    req.setRequestHeader("pragma", "public, max-age=60");
    req.send(null);
  });

const GeoTips: React.FC<unknown> = () => {
  const [unauthorized, setUnauthorized] = useState<boolean>(false);

  useEffect(() => {
    const shouldIgnore = !!window.sessionStorage.getItem("GEO_STORAGE_KEY");
    getGeoCodeFromHttpHeaders()
      .then((code) => {
        const isUnauthorized = unauthorizedGeoCode.includes(code);
        if (!isUnauthorized || shouldIgnore) return;
        setUnauthorized(true);
      })
      .catch((err) => {
        console.log("GeoAuth: ", err);
        if (shouldIgnore) return;
        setUnauthorized(true);
      });
  }, []);

  return (
    <Modal
      visible={unauthorized}
      onConfirm={() => (window.location.href = t("geo_link"))}
      btnText={t("geo_more")}
      content={t("geo_tips", "Cosmos")}
    />
  );
};

export default GeoTips;
