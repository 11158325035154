const networkConfig = {
  dev: {
    denom: 'muon',
    chainAPI: 'https://api.imdev.works/v1beta1/cosmos',
    market: 'https://dexapi.imdev.works/rpc',
    campaign: 'https://biz.imdev.works/rpc',
    exchange: 'https://exchange-agent.imdev.works/rpc',
  },
  staging: {
    denom: 'uatom',
    chainAPI: 'https://api.imstaging.works/v1beta1/cosmos',
    market: 'https://dexapi.imstaging.works/rpc',
    campaign: 'https://biz.imstaging.works/rpc',
    exchange: 'https://exchange-agent.imstaging.works/rpc',
  },
  prod: {
    denom: 'uatom',
    chainAPI: 'https://biz.token.im/v1beta1/cosmos',
    market: 'https://mainnet-dexapi.token.im/rpc',
    campaign: 'https://mainnet-bizapi.tokenlon.im/rpc',
    exchange: 'https://exchange-agent.tokenlon.im/rpc',
  },
}

export default () => {
  const isDev = window.location.host.indexOf('.imdev.') !== -1
  const isStaging = window.location.host.indexOf('.imstaging.') !== -1
  const env = isDev ? 'dev' : isStaging ? 'staging' :'prod'
  return networkConfig[env]
}
