enum MsgType {
  send = '/cosmos.bank.v1beta1.MsgSend',
  delegate = '/cosmos.staking.v1beta1.MsgDelegate',
  undelegate = '/cosmos.staking.v1beta1.MsgUndelegate',
  withdraw = '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
  redelegate = '/cosmos.staking.v1beta1.MsgBeginRedelegate',
  vote = '/cosmos.gov.v1beta1.MsgVote',
  proposal = '/cosmos.gov.v1beta1.MsgSubmitProposal',
  deposit = '/cosmos.gov.v1beta1.MsgDeposit',
  textProposal = '/cosmos.gov.v1beta1.TextProposal',
}
export default MsgType
