import React, { useEffect, useState } from "react";
import "./animations.scss";
import styles from "./index.module.scss";
import GlassLayer from "./GlassLayer";
import CssTransition from "./CssTransition";
import { isiPhoneX } from "lib/utils";
import useBodyScroll from "./useBodyScroll";
import usePortal from "./usePortal";
import { createPortal } from "react-dom";
import geoTipsImage from "assets/geoTips.svg";
import circularCloseImage from "assets/circularClose.svg";

export interface ModalProps {
  visible?: boolean;
  onClose?: React.EventHandler<React.MouseEvent>;
  onConfirm?: React.EventHandler<React.MouseEvent>;
  title?: string;
  content: string;
  btnText?: string;
  zIndex?: number;
}

const marginBottom = isiPhoneX() ? 40 : 20;

const Modal: React.FC<ModalProps> = ({
  visible: customVisible,
  onConfirm,
  title,
  content,
  btnText,
  zIndex = 1000,
}) => {
  const [, setBodyScroll] = useBodyScroll();
  const portal = usePortal("geo-modal");
  const [visible, setVisible] = useState<boolean>(() => customVisible || false);

  useEffect(() => {
    if (customVisible !== undefined) {
      setVisible(customVisible);
    }
  }, [customVisible]);

  useEffect(() => {
    setBodyScroll(visible || false);
  }, [visible]);

  const confirmHandler = (event: React.MouseEvent<any>) => {
    onConfirm && onConfirm(event);
  };

  if (!portal) return null;

  return createPortal(
    <CssTransition name="geo-animations" visible={visible} clearTime={350}>
      <div className={styles.content}>
        <GlassLayer zIndex={zIndex - 1} className="geo-animations-layer" />
        <div
          className={`${styles.container} geo-animations-content`}
          style={{ marginBottom: `${marginBottom}px`, zIndex: zIndex }}
        >
          <div className={styles.texts}>
            <img src={geoTipsImage} alt="warning" draggable={false} />
            {title && <h2>{title}</h2>}
            <p>{content}</p>

            <button
              className={styles.btn}
              onClick={confirmHandler}
              onTouchStart={() => {}}
            >
              {btnText || "ok"}
            </button>
          </div>
        </div>
      </div>
    </CssTransition>,
    portal
  );
};

export default Modal;
