export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_VALIDATORS = 'UPDATE_VALIDATORS'
export const UPDATE_DELEGATIONS = 'UPDATE_DELEGATIONS'
export const UPDATE_POOL = 'UPDATE_POOL'
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const UPDATE_VALIDATOR_REWARDS = 'UPDATE_VALIDATOR_REWARDS'
export const UPDATE_ATOM_PRICE = 'UPDATE_ATOM_PRICE'
export const UPDATE_SORTBY = 'UPDATE_SORTBY'
export const UPDATE_REDELEGATIONS = 'UPDATE_REDELEGATIONS'
export const ADD_PENDING_TX = 'ADD_PENDING_TX'
export const REMOVE_PENDING_TX = 'REMOVE_PENDING_TX'
export const UPDATE_EXCHANGE_TOKEN = 'UPDATE_EXCHANGE_TOKEN'
export const UPDATE_UNBONDING_DELEGATIONS = 'UPDATE_UNBONDING_DELEGATIONS'
export const UPDATE_SELECTED_VALIDATOR = 'UPDATE_SELECTED_VALIDATOR'

export const UPDATE_PROPOSALS = 'UPDATE_PROPOSALS'
