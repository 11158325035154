import { any } from "prop-types"

type Tx = {
  txHash: string
  walletAddress: string
  msgType: string,
  status: string,
  validatorId?: string
  value: Object,
  fee: Object
}

export const selectAccountInfo = state => state.account
export const selectValidators = state => state.validators
export const selectDelegations = state => state.delegations
export const selectPool = state => state.pool
export const selectValidatorRewards = state => state.validatorRewards
export const selectPrice = state => state.price
export const selectSortby = state => state.sortBy
export const selectRedelegations = state => state.redelegations

export const selectPendingTxs = state => {
  const address = state?.account?.address ?? ''
  const txs =  Object.values(state.pendingTxs).filter((tx: Tx) => {
    return tx?.walletAddress === address
  }).reduce((txs: Record<string, Tx>, currentTx: Tx) => {
    txs[currentTx.txHash] =currentTx
    return txs
  }, {})
  return txs
}

export const selectExchangeToken = state => state.exchangeToken
export const selectUnbondingDelegations = state => state.unbondingDelegations
export const selectSelectedValidator = state => state.selectedValidator

export const selectProposals = state => state.proposals
