import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ValidatorLogo from '../../components/validatorLogo'
import './index.scss'
import { fAtom, fPercent, t } from 'lib/utils'
import SELECTED from '../../assets/selected.svg'

interface Props {
  validator: any
  pool?: any
  isHideBadge?: boolean
  index: number
  onSelect?: any
  selectedValidator?: any
}
class CMP extends Component<Props> {
  render() {

    const { onSelect, validator } = this.props
    const v = validator
    // const totalPoolBondedTokens = pool.bonded_tokens
    // const percent = atom(v.tokens) / atom(totalPoolBondedTokens)

    if (onSelect) {
      return (
        <div onClick={() => onSelect(v)}>
          {this.renderInner()}
        </div>
      )
    }

    return (
      <Link to={`/validator/${v.operator_address}`}>
        {this.renderInner()}
      </Link>
    )
  }

  renderInner() {
    const { validator, isHideBadge, index, selectedValidator } = this.props
    const isSelected = selectedValidator?.operator_address === validator.operator_address
    const cl = isSelected ? 'selected' : (index % 2 === 0 ? 'even-row' : 'odd-row')
    const jailed = validator.jailed

    return (
      <div className={`validator ${cl}`}>
        {!isHideBadge && <div className="validator-rank-badge">{validator.sortIndex + 1}</div>}
        <ValidatorLogo url={validator.description.logo} />
        <div className="v-left">
          <div className="v-row">
            <strong className={jailed && 'jailed-text'}>{validator.description.moniker}</strong>
            {jailed && <div className="jailed-badge">{'jailed'}</div>}
          </div>
          <span>{`${fAtom(validator.tokens, 0)} ATOM`}</span>
        </div>
        {
          isSelected ? <img src={SELECTED} alt="selected" /> :
        <div className="v-right">
          <strong className={jailed && 'jailed-text'}>{fPercent(validator.annualized_returns, 2)}</strong>
          <span>{t('yield')}</span>
        </div>}
      </div>
    )
  }
}

export default CMP
