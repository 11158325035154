export enum PROPOSAL_STAGE {
  DepositPeriod = 'PROPOSAL_STATUS_DEPOSIT_PERIOD',
  VotingPeriod = 'PROPOSAL_STATUS_VOTING_PERIOD',
  Passed = 'PROPOSAL_STATUS_PASSED',
  Rejected = 'PROPOSAL_STATUS_REJECTED',
  Failed = 'PROPOSAL_STATUS_FAILED',
}

export const PROPOSAL_STAGE_MAP = {
  [PROPOSAL_STAGE.DepositPeriod]: 'DepositPeriod',
  [PROPOSAL_STAGE.VotingPeriod]: 'VotingPeriod',
  [PROPOSAL_STAGE.Passed]: 'Passed',
  [PROPOSAL_STAGE.Rejected]: 'Rejected',
  [PROPOSAL_STAGE.Failed]: 'Failed',
}

export const BOND_STATUS_UNSPECIFIED = 'BOND_STATUS_UNSPECIFIED'
export const BOND_STATUS_UNBONDED = 'BOND_STATUS_UNBONDED'
export const BOND_STATUS_UNBONDING = 'BOND_STATUS_UNBONDING'
export const BOND_STATUS_BONDED = 'BOND_STATUS_BONDED'

export enum VoteOption {
  VoteOptionUnspecified,
  VoteOptionYes,
  VoteOptionAbstain,
  VoteOptionNo,
  VoteOptionNoWithVeto,
}

export const VoteOptionMap = {
  Yes: VoteOption.VoteOptionYes,
  No: VoteOption.VoteOptionNo,
  NoWithVeto: VoteOption.VoteOptionNoWithVeto,
  Abstain: VoteOption.VoteOptionAbstain
}
