const zh_CN = {
  available_balance: "可用余额",
  rewards: "收益",
  delegations: "委托",
  delegate: "委托",
  delegated: "已委托",
  undelegate: "赎回委托",
  undelegating: "赎回中",
  withdraw: "赎回",
  withdraw_reward: "赎回收益",
  reinvest_reward: "复投收益",
  withdraw_all: "全部赎回",
  redelegate: "更换委托",
  my_delegations: "我的委托",
  validators: "验证节点",
  yield: "预计年化收益",
  rewards_per_day: "预计收益(天)",
  accessing_account: "获取账号中...",
  commission_rate: "手续费",
  self_bonded_tokens: "验证节点委托代币",
  failed_to_send: "发送失败",
  sent_successfully: "交易广播成功",
  input_amount: "输入数量",
  proposal_vote: "提案投票",
  coming_soon: "即将上线",
  faq: "常见问题",
  tutorial: "使用教程",
  invalid_number: "无效的数量",
  number_must_be_positive: "请输入大于 0 的数量",
  decimal_length_must_lt_six: "小数位不能超过 6 位",
  fee_not_enough: "可用余额不足以支付矿工费", // 待优化
  more_than_available: "超出可用数量",
  please_open_in_imtoken: "请在 imToken 中打开",
  sort: "排序",
  delegators: "委托者",
  bonded_tokens: "委托代币",
  cancel: "取消",
  confirm: "确认",
  fee: "交易费",
  select_funds_type: "选择资金类型",
  other_delegations: "其他委托",
  change: "更换",
  none: "暂无",
  intro: "简介",
  transactions: "最近交易",
  all: "全部",
  activity: "活动",
  free_commission_high_yield: "免佣金，高收益",
  advantage: "特点",
  hide_availability: "高可用",
  anti_ddos: "抗 DDOS",
  sentry_node: "哨兵节点",
  unavailable: "暂不可用",
  redelegate_incompletion:
    "最近 21 天内已对当前验证节点更换委托，暂时不能再次更换委托",
  tx_pending: "交易正在打包",
  tx_success: "交易成功",
  tx_failed: "交易失败",
  withdraw_reward_desc: "赎回所有收益 $s, 并支付交易费 $s",
  reinvest_reward_desc: "赎回并委托收益 $s 至验证节点，并支付交易费 $s",
  no_rewards: "暂时没有收益",
  understood: "知道了",
  update_app_title: "升级至 imToken 最新版本",
  update_app_desc:
    "由于当前 imToken 版本过低，无法使用该应用。请使用系统浏览器访问 https://token.im 升级 imToken。",
  no_wallet_title: "未发现 COSMOS 钱包",
  no_wallet_desc: "请使用 V2.4.0 及以上版本在钱包管理中创建 COSMOS 钱包",
  exchange_atom: "兑换 ATOM",
  exchange_atom_desc: "请确认兑换 ATOM 收款地址",
  cant_exchange_now: "暂时无法购买",
  quick_exchange_atom: "一键兑换 ATOM",
  quick_exchange_atom_desc: "限时免手续费",
  risk: "风险",
  delegations_state:
    "委托 ATOM 给验证节点可以随时赎回，但赎回委托需要等待 21 天。",
  rewards_state: "委托收益来自出块奖励和交易手续费。",
  risk_state:
    "如果验证节点行为不端将可能会被罚没部分委托代币，比如验证节点双重签名、经常性离线。为了规避风险，请认真选择合格的验证节点。",
  state: "说明",
  undelegate_state: "赎回委托需等待 21 天，同时赎回期间的委托不会产生收益。",
  redelegate_state:
    "更换委托至验证节点后，该验证节点需要等待 21 后才可以更换委托。",
  more_details: "更多详情",
  campaign_over: "活动已结束",
  search_validator: "搜索验证节点名称",
  search_for_nothing: "搜索不到该节点",
  delegation_status: "委托状态",
  completion: "到账",
  select_validator: "选择验证节点",
  no_delegation: "暂无委托",
  recommend: "推荐",

  // Vote
  vote_title: "提案投票",
  vote: "投票",
  deposit: "抵押",
  yes: "赞同",
  no: "反对",
  nowithveto: "强烈反对",
  abstain: "弃权",
  passed: "已通过",
  rejected: "已否决",
  depositperiod: "抵押期",
  Voting: "投票中",
  votingperiod: "投票中",
  quorum: "法定人数",
  most_voted_on: "主要倾向",
  your_vote: "你的投票",
  your_deposit: "你的抵押",
  voting_end: "投票结束时间",
  deposit_end_time: "抵押结束时间",
  input_deposit_amount: "投票代币数",
  geo_tips:
    "为匹配用户属地国家的监管政策，$s 委托工具已于新加坡时间 2021 年 10 月 31 日起，停止向中国境内用户提供服务。",
  geo_more: "了解更多",
  geo_link: "https://support.token.im/hc/zh-cn/articles/4407190608665",
};

export default zh_CN;
