import axios from 'axios'
import { getProvider } from './sdk'
import { getRewardBalance, t } from './utils'
import getNetworkConfig from '../config/network'
import msgTypes from './msgTypes'

const API_VERSION = 'v1beta1'

/**
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ node requests ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

// let _headers = null
// let _provider = "https://cosmosapi-mainnet.tokenlon.im"
let _provider = null

export async function initRequestDependency() {
  return {
    provider: _provider || (await getProvider()),
  }
}

function get(url, params = {}) {
  return initRequestDependency().then(({ provider }) => {
    const _url = `${provider}/${url}`
    return axios({ method: 'get', url: _url, params }).then(res => {
      if (res.data) {
        return res.data
      } else {
        throw new Error(`null response ${url} ${JSON.stringify(params)}`)
      }
    })
  })
}

function post(url, params = {}) {
  return initRequestDependency().then(({ provider }) => {
    const _url = `${provider}/${url}`
    return axios.post(_url, params).then(res => {
      if (res.data) {
        return res.data
      } else {
        throw new Error(`null response ${url} ${JSON.stringify(params)}`)
      }
    })
  })
}

function rpc(url, method, params) {
  return initRequestDependency().then(() => {
    const data = {
      jsonrpc: '2.0',
      id: 1,
      method,
      params,
    }
    return axios({ method: 'post', url, data }).then(res => {
      if (res.data) {
        return res.data.result
      } else {
        throw new Error(`null response ${url} ${JSON.stringify(params)}`)
      }
    })
  })
}

function sortValidators(a, b) {
  return a.tokens * 1 > b.tokens * 1 ? -1 : 1
}

// Normalize account response
export type CosmosAccount = {
  sequence: string
  account_number: string
  address: string
  pub_key: {
    '@type': string
    key: string
  }
  '@type': string
}
export const getAccount = async (address: string): Promise<CosmosAccount> => {
  const emptyAccount = {
    sequence: `0`,
    account_number: `0`,
  }
  const url = `/cosmos/auth/${API_VERSION}/accounts/${address}`
  const res = await get(url, {})
  return { ...emptyAccount, ...res?.account }
}

export type CosmosCoin = {
  amount: string
  denom: string
}
export const getBalance = async (address: string): Promise<CosmosCoin[]> => {
  const url = `/cosmos/bank/${API_VERSION}/balances/${address}`
  const res = await get(url, {})
  return res.balances ?? []
}

export type Delegation = {
  delegation: {
    delegator_address: string
    validator_address: string
    shares: string
  }
  balance: {
    denom: string
    amount: string
  }
}
export const getDelegations = async (address: string): Promise<Delegation[]> => {
  const url = `/cosmos/staking/${API_VERSION}/delegations/${address}`
  const delegations = await get(url, {}).then(res => {
    return (res.delegation_responses ?? []).sort((a, b) => b.balance.amount - a.balance.amount)
  })
  return delegations
}

export type DelegatorReward = {
  denom: string
  amount: string
}
export const getRewards = async (address: string): Promise<DelegatorReward[]> => {
  const url = `/cosmos/distribution/${API_VERSION}/delegators/${address}/rewards`
  return get(url, {}).then(res => res.total ?? [])
}

export type UnBondingDelegation = {
  delegator_address: string
  validator_address: string
  entries: {
    initial_balance: string
    balance: string
    creation_height: string
    completion_time: string
  }[]
}
export const getUnbondingDelegations = async (address: string): Promise<UnBondingDelegation[]> => {
  const url = `/cosmos/staking/${API_VERSION}/delegators/${address}/unbonding_delegations`
  return get(url, {}).then(res => res.unbonding_responses ?? [])
}

export const getStakePool = () => {
  const url = `/cosmos/staking/${API_VERSION}/pool`
  return get(url, {}).then(({ pool }) => ({
    bonded_tokens: Number(pool.bonded_tokens),
  }))
}

/**
 * [
  {
    "delegator_address": "cosmos18ptg027t5cumqzkhpn726uhdqdadh88ss7ytv3",
    "validator_src_address": "cosmosvaloper1cgh5ksjwy2sd407lyre4l3uj2fdrqhpkzp06e6",
    "validator_dst_address": "cosmosvaloper1rwh0cxa72d3yle3r4l8gd7vyphrmjy2kpe4x72",
    "entries": [
      {
        "creation_height": "97157",
        "completion_time": "2019-05-21T09:43:35.605908506Z",
        "initial_balance": "2200000",
        "shares_dst": "2200000.000000000000000000"
      }
    ]
  }
]
 */
export const getRedelegations = (delegateAddr: string) => {
  const url = `/cosmos/staking/${API_VERSION}/delegators/${delegateAddr}/redelegations`
  return get(url, {}).then(({ redelegation_responses }) => redelegation_responses || [])
}

export const getMyRewardByValidator = (delegatorAddr, validatorAddr) => {
  const url = `/cosmos/distribution/${API_VERSION}/delegators/${delegatorAddr}/rewards/${validatorAddr}`
  return get(url, {}).then(({ rewards }) => {
    rewards = rewards || []
    const balance = getRewardBalance(rewards)
    return balance
  })
}

/**
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ vote ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

export interface IProposal {
  proposal_id: string // '1'
  status: string
  content: {
    '@type': string // 'cosmos-sdk/TextProposal'
    description: string
    title: string
  }
  final_tally_result: {
    abstain: string // "402380577234"
    no: string // "320545400000"
    no_with_veto: string // "0"
    yes: string // "97118903526799"
  }
  submit_time: string // "2019-03-20T06:41:27.040075748Z"
  voting_start_time: string // "2019-04-03T20:43:59.630492307Z"
  voting_end_time: string // "2019-04-03T20:43:59.630492307Z"
  deposit_end_time: string // "2019-04-03T20:43:59.630492307Z"
  total_deposit: Array<{
    denom: string // 'uatom
    amount: string // "512100000"
  }>
}

export const getProposals = (): Promise<IProposal[]> => {
  const url = `/cosmos/gov/${API_VERSION}/proposals`
  return get(url, {}).then(({ proposals }) => (proposals || []).reverse())
}

export const getProposalVoters = (id: string) => {
  const url = `/cosmos/gov/${API_VERSION}/proposals/${id}/votes`
  return get(url, {}).then(({ votes }) => votes ?? [])
}

export const getProposalTally = (id: string) => {
  const url = `/cosmos/gov/${API_VERSION}/proposals/${id}/tally`
  return get(url, {}).then(({ tally }) => tally)
}

/**
 *
 * {
  "proposal_id": "25",
  "depositor": "cosmos1v5hrqlv8dqgzvy0pwzqzg0gxy899rm4kth4jr4",
  "amount": [
    {
      "denom": "uatom",
      "amount": "400000000"
    }
  ]
}
 */

export const getProposalDepositByAddress = (proposalId: string, depositor: string) => {
  const url = `/cosmos/gov/${API_VERSION}/proposals/${proposalId}/deposits/${depositor}`
  return get(url, {}).then(({ deposit }) => deposit)
}

/**
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ vote end ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

export const getTxByHash = (txHash: string) => {
  const url = `/cosmos/tx/${API_VERSION}/txs//${txHash}`
  return get(url, {}).then(({ tx_response }) => tx_response)
}

/**
 * check tx if failed
 */

// function checkTxRawLog(raw_log) {
//   try {
//     const rawlog = JSON.parse(raw_log)
//     if (Array.isArray(rawlog)) {
//       return rawlog.every(r => r.success === true)
//     }
//   } catch (error) {
//     // if raw_log is not parsed successfully, take it as failed
//   }
//   return false
// }

/**
 * polling check tx
 */
export function checkTx(txHash, timer, repeatCount = 10) {
  let count = 0

  const check = (resolve, reject) => {
    return getTxByHash(txHash)
      .then(tx => {
        if (tx && tx.height) {
          resolve(tx)
        } else {
          reject({ message: t('tx_failed') })
        }
      })
      .catch(e => {
        if (count > repeatCount) {
          reject(e)
          return
        }
        setTimeout(() => {
          count++
          check(resolve, reject)
        }, timer || 5000)
      })
  }

  return new Promise((resolve, reject) => {
    check(resolve, reject)
  })
}

/**
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ server rpc requests ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

export async function getValidators() {
  const host = getNetworkConfig().chainAPI
  return rpc(host, `wallet.getValidators`, [])
    .then(validators => {
      return (validators || []).map(v => ({
        ...v,
        tokens: v.Tokens || v.tokens,
      }))
    })
    .then(validators =>
      validators.sort(sortValidators).map((v, index) => {
        return { ...v, sortIndex: index }
      }),
    )
}

export async function getAtomPrice() {
  const host = getNetworkConfig().market
  const currency = 'USD'
  const params = [{ chainType: 'COSMOS', address: 'uatom', currency }]
  return rpc(host, `market.getPrice`, params).then(prices => prices || {})
}

export function getTxListByAddress(delegator: string, validator: string) {
  const params = [
    {
      address: delegator,
      relativeAddress: validator,
      msgTypes: [msgTypes.delegate, msgTypes.undelegate, msgTypes.withdraw, msgTypes.redelegate],
    },
  ]
  return rpc(getNetworkConfig().chainAPI, 'wallet.getMsgListByAddress', params).then(
    data => data || [],
  )
}

interface GetFeeGearsResponse {
  defaultGear: number
  expiredAt: number
  gears: { gasPrice: string; speed: string }[]
  provider: string
  updateAt: number
}

export function getFeeGears() {
  return rpc(getNetworkConfig().chainAPI, 'wallet.getFeeGears', []) as Promise<GetFeeGearsResponse>
}

export function getHashquarkRankList(address: string) {
  const params = [
    {
      address,
    },
  ]
  return rpc(getNetworkConfig().campaign, 'campaign.hashquarkRankList', params).then(data => data)
}

export function getTradeTokenList() {
  return rpc(getNetworkConfig().exchange, 'tokenlon.getTradeTokenList', {}).then(data => data)
}

export function getGas(params) {
  return post('cosmos/tx/v1beta1/simulate', params).then(res => {
    return res?.gas_info?.gas_used
  })
}
