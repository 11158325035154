import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isBrowser, isiOS } from "lib/utils";

export type ElementStackItem = {
  last: string | null;
};

export type BodyScrollOptions = {
  scrollLayer: boolean;
};

const defaultOptions: BodyScrollOptions = {
  scrollLayer: false,
};
const elementStack = new Map<HTMLElement, ElementStackItem>();

const touchHandler = (event: TouchEvent): boolean => {
  if (event.touches && event.touches.length > 1) return true;
  event.preventDefault();
  return false;
};

const useBodyScroll = (
  elementRef?: RefObject<HTMLElement> | null,
  options?: BodyScrollOptions
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  // if (!isBrowser()) return [false, () => {}];
  const bodyRef = useRef<HTMLElement>(document.body);
  const elRef = useMemo(() => elementRef || bodyRef, [elementRef, bodyRef]);
  const [hidden, setHidden] = useState<boolean>(false);
  const safeOptions = {
    ...defaultOptions,
    ...(options || {}),
  };

  // don't prevent touch event when layer contain scroll
  const isIosWithCustom = () => {
    if (safeOptions.scrollLayer) return false;
    return isiOS();
  };

  useEffect(() => {
    if (!elRef || !elRef.current) return;
    const lastOverflow = elRef.current.style.overflow;
    if (hidden) {
      if (elementStack.has(elRef.current)) return;
      if (!isIosWithCustom()) {
        elRef.current.style.overflow = "hidden";
      } else {
        document.addEventListener("touchmove", touchHandler, {
          passive: false,
        });
      }
      elementStack.set(elRef.current, {
        last: lastOverflow,
      });
      return;
    }

    // reset element overflow
    if (!elementStack.has(elRef.current)) return;
    if (!isIosWithCustom()) {
      const store = elementStack.get(elRef.current) as ElementStackItem;
      elRef.current.style.overflow = store.last;
    } else {
      document.removeEventListener("touchmove", touchHandler);
    }
    elementStack.delete(elRef.current);
  }, [hidden, elRef]);

  return [hidden, setHidden];
};

export default useBodyScroll;
