import BN from 'big.js'
import getNetworkConfig from '../config/network'
import { getFeeGears, getGas } from '../lib/api'
import msgTypes from '../lib/msgTypes'
import { AccountInfo } from '../lib/utils'

export const gasLimitMap = {
  [msgTypes.send]: 100000,
  [msgTypes.delegate]: 200000,
  [msgTypes.undelegate]: 200000,
  [msgTypes.withdraw]: 200000,
  [msgTypes.redelegate]: 300000,
  [msgTypes.vote]: 100000,
  [msgTypes.proposal]: 100000,
  [msgTypes.deposit]: 200000,
}

export type MsgType = {
  typeUrl: msgTypes
  value: Object
}

/** get gasPrice from api */
export const getGasPrice = async () => {
  try {
    const res = await getFeeGears()
    const availableGear = res?.gears?.find(i => !Number.isNaN(Number(i.gasPrice)))
    return Number(availableGear.gasPrice ?? 0.025)
  } catch (error) {
    console.error(error)
    return 0.025
  }
}

export const getFeeAmountByType = async (type: keyof typeof gasLimitMap) => {
  const gasLimit = gasLimitMap[type]
  const gasPrice = await getGasPrice()
  return new BN(gasPrice).times(gasLimit).toFixed()
}

const genTxRequestParams = async (msgs: MsgType[], account: AccountInfo) => {
  const gasPrice = await getGasPrice()
  const feeAmount = msgs
    .map(msg => {
      return {
        amount: new BN(gasPrice).times(gasLimitMap[msg.typeUrl]),
        denom: getNetworkConfig().denom,
      }
    })
    .reduce((prev, cur) => {
      return prev.plus(cur.amount)
    }, new BN(0))

  return {
    body: {
      messages: msgs.map(msg => {
        return {
          '@type': msg.typeUrl,
          ...msg.value,
        }
      }),
      memo: 'imToken-DeFiApi',
      timeout_height: '0',
      extension_options: [],
      non_critical_extension_options: [],
    },
    auth_info: {
      signer_infos: [
        {
          public_key: account.pub_key ?? null,
          mode_info: {
            single: {
              mode: 'SIGN_MODE_LEGACY_AMINO_JSON',
            },
          },
          sequence: account.sequence ?? '0',
        },
      ],
      fee: {
        amount: [
          {
            amount: feeAmount,
            denom: getNetworkConfig().denom,
          },
        ],
        payer: '',
        granter: '',
      },
    },
    signatures: [''],
  }
}

const GAS_BUFFER_RATE = 1.5

export const getEstimateGas = async (msgs: MsgType[], account: AccountInfo) => {
  const txParams = await genTxRequestParams(msgs, account)

  try {
    const gas = await getGas({ tx: txParams })
    if (gas) {
      return new BN(gas).mul(GAS_BUFFER_RATE).toFixed(0)
    }
  } catch (e) {}

  return msgs.reduce((prev, cur) => {
    return prev.plus(new BN(gasLimitMap[cur.typeUrl]))
  }, new BN(0))
}

export const getFeeParamsByMsgs = async (msgs: MsgType[], account: AccountInfo) => {
  const gasLimit = await getEstimateGas(msgs, account)
  const gasPrice = await getGasPrice()

  const feeAmount = new BN(0).plus(new BN(gasPrice).times(gasLimit)).toFixed(0)
  return {
    feeAmount,
    gasLimit,
  }
}

export const getFeeAmountByMsgs = async msgs => {
  const gasPrice = await getGasPrice()
  let feeBN = new BN(0)
  msgs.forEach(msg => {
    const _gasLimit = gasLimitMap[msg.typeUrl]
    feeBN = feeBN.plus(new BN(gasPrice).times(_gasLimit))
  })
  return feeBN.toFixed(0)
}
