const en_US = {
  available_balance: "Available",
  rewards: "Rewards",
  delegations: "Delegations",
  delegate: "Delegate",
  delegated: "Delegated",
  undelegate: "Undelegate",
  undelegating: "Undelegating",
  withdraw: "Withdraw",
  withdraw_reward: "Withdraw",
  reinvest_reward: "Reinvest",
  withdraw_all: "Withdraw All",
  redelegate: "Redelegate",
  my_delegations: "My Delegations",
  validators: "Validators",
  yield: "Yield",
  rewards_per_day: "Rewards (Est. per day)",
  accessing_account: "Accessing Account...",
  commission_rate: "Commission Rate",
  self_bonded_tokens: "Self Bonded Tokens",
  failed_to_send: "Failed",
  sent_successfully: "Transaction Broadcast Successfully",
  input_amount: "Input Amount",
  proposal_vote: "Proposal Vote",
  coming_soon: "Coming Soon",
  faq: "FAQ",
  tutorial: "Tutorial",
  invalid_number: "Invalid number",
  number_must_be_positive: "The number must be greater than 0",
  decimal_length_must_lt_six: "Decimal length must less than 6",
  fee_not_enough: "Fee not enough",
  more_than_available: "The number is greater than available asset",
  please_open_in_imtoken: "Please open in imToken",
  sort: "Sort",
  delegators: "Delegators",
  bonded_tokens: "Bonded Tokens",
  cancel: "Cancel",
  confirm: "Confirm",
  fee: "Fee",
  select_funds_type: "Select Funds Type",
  other_delegations: "Other Delegations",
  change: "Change",
  none: "None",
  intro: "Intro",
  transactions: "Recent Transactions",
  all: "All",
  activity: "Activity",
  free_commission_high_yield: "Free commission, high yield",
  advantage: "Advantage",
  high_availability: "High Availability",
  anti_ddos: "Anti DDOS",
  sentry_node: "Sentry Node",
  unavailable: "Unavailable",
  redelegate_incompletion:
    "You can't change the delegate, since you recently changed it. Please wait for the 21 day lock period to end!",
  tx_pending: "Transaction Pending",
  tx_success: "Transaction Successfully",
  tx_failed: "Failed",
  withdraw_reward_desc: "Withdraw reward $s and pay transactions fee $s",
  reinvest_reward_desc:
    "Withdraw & delegate rewards to the origin validator $s. You may pay transaction fee $s",
  no_rewards: "No Rewards",
  understood: "Understood",
  update_app_title: "Update imToken to latest version",
  update_app_desc:
    "Current imToken doesn't work with this dapp. Please visit https://token.im via system browser and download the latest version",
  no_wallet_title: "No COSMOS wallets found",
  no_wallet_desc:
    "Please create COSMOS wallet in wallets manage with V2.4.0 or above version",
  exchange_atom: "Exchange ATOM",
  exchange_atom_desc: "Please confirm your wallet address",
  cant_exchange_now: "Tokenlon is not available now",
  quick_exchange_atom: "Exchange ATOM immediately",
  quick_exchange_atom_desc: "Free fee time limited",
  risk: "Risk",
  delegations_state:
    "Delegated ATOM can be un-delegated (i.e redeemed) at any time. But, it takes 21 days to be unlocked for further usage such as sending.",
  rewards_state:
    "Delegation rewards come from validation bonuses and transaction fees.",
  risk_state:
    "If the validator misbehaves, such as double signing or frequently being offline, it may lose some of your delegated tokens. In order to avoid risks, please carefully select qualified validators. More details here.",
  state: "Note",
  undelegate_state:
    "Un-delegation (i.e. withdrawal) takes immediate effect, but un-delegated ATOM is locked, i.e. unavailable for any action - such as delegation or sending - for 21 days. You won't gain any rewards on this ATOM during this period.",
  redelegate_state:
    "The re-delegation will take effect immediately, i.e. your re-delegated ATOM will earn rewards. But, it will be locked for 21 days, meaning you won't be able to re-delegation again for 21 days.",
  more_details: "More details here.",
  campaign_over: "The event has ended",
  search_validator: "Search validator name",
  search_for_nothing: "Not Found",
  delegation_status: "Delegation Status",
  completion: "completion",
  select_validator: "select validator",
  no_delegation: "No Delegation",
  recommend: "Sponsored",

  // Vote
  vote_title: "Voting Proposal",
  vote: "Vote",
  deposit: "Deposit",
  yes: "Yes",
  no: "No",
  nowithveto: "NoWithVeto",
  abstain: "Abstain",
  passed: "Passed",
  rejected: "Rejected",
  depositperiod: "DepositPeriod",
  votingperiod: "VotingPeriod",
  Voting: "Voting",
  quorum: "Quorum",
  most_voted_on: "Most Voted On",
  your_vote: "your vote",
  your_deposit: "your deposit",
  voting_end: "Voting End Time",
  deposit_end_time: "Deposit End Time",
  input_deposit_amount: "Voting balance",
  geo_more: "Learn more",
  geo_tips:
    "In order to match the regulatory policy of the user's country of origin, $s staking tool has ceased to provide the service to users in China as of 31 October 2021 SGT.",
  geo_link: "https://support.token.im/hc/en-us/articles/4407190608665",
};

export default en_US;
